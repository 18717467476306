<template>
  <div>
  <b-table hover :fields="fields"  :items="locationData">
    <template #cell(lat_lng)="data">
      <b-button size="sm" @click="refreshLatLng(data.item)"><i class="fa fa-refresh"></i></b-button> {{ data.value }}
    </template>
    <template #cell(action)="data">
        <b-button size="sm" variant="danger" @click="removeLocation(data.item.id)"><i class="fa fa-remove"></i></b-button>
      </template>
  </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'LocationList',
  data () {
    return {
      fields: [
        'id',
        'name',
        'address',
        'address2',
        'zip_code',
        { key: 'lat_lng', label: 'Lat / Lng' },
        { key: 'action', label: '' }
      ]
    }
  },
  computed: {
    ...mapGetters(['business', 'eventLocations']),
    locationData () {
      let l = []
      for (let i in this.eventLocations) {
        l.push({
          id: this.eventLocations[i].id,
          name: this.eventLocations[i].name,
          address: this.eventLocations[i].address1,
          address_2: this.eventLocations[i].address2,
          zip_code: this.eventLocations[i].postalCode,
          lat_lng: this.eventLocations[i].lat + ', ' + this.eventLocations[i].lng
        })
      }
      return l
    }
  },
  methods: {
    ...mapActions(['getEventLocations', 'putEventLocation']),
    refreshLatLng (data) {
      let addressObj = {
          address_line_1: data.address,
          address_line_2: data.address_2,
          zip_code:       data.zip_code,
          country:        'United States'
      }
      this.$geocoder.send(addressObj, response => {
        let location = response.results[0].geometry.location
        this.putEventLocation({
          id: data.id,
          lat: location.lat.toString(),
          lng: location.lng.toString()
        }).then(() => {
          this.doGetEventLocations()
          this.showSuccess()
        })
      })
    },
    removeLocation (id) {
      if (!confirm('remove the location from this list?')) {
        return
      }
      this.putEventLocation({
        id: id,
        active: false
      }).then(() => {
        this.doGetEventLocations()
        this.showSuccess()
      })
    },
    doGetEventLocations () {
      this.getEventLocations({
        business: this.business['@id'],
        active: true
      })
    }
  },
  created () {
    this.doGetEventLocations()
  }
}
</script>